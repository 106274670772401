<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title color="primary">
        <h4 color="primary">
          Locker Detail
        </h4>
      </v-toolbar-title>
    </v-toolbar>

    <div>
      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class="nested-label-col-1">
          <span class="label-title">Id</span>
        </v-col>
        <v-col md="3" class="nested-label-col-2">
          <span class="label nested-label-col-2">T78-xx-xx</span>
        </v-col>
      </v-row>

      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class=" nested-label-col-1">
          <span class="label-title">Name</span>
        </v-col>
        <v-col md="3" class="nested-label-col-2">
          <span class="label nested-label-col-2">Tajimi admin</span>
        </v-col>
      </v-row>

      <v-row >
        <v-col cols="12" md="2" xs="3" class="nested-label-col-1">
          <span class="label label-title">Location</span>
        </v-col>

        <v-col cols="12" md="2" xs="3" class="sublist-desktop-col sublist-mobile-col">
          <v-row align="center">
            <span class="label label-title sublist-mobile-title" >Postal Code</span>
          </v-row>
          <v-row align="center">
            <span class="label label-title sublist-mobile-title">Address</span>
          </v-row>
        </v-col>

        <v-col cols="12" md="5"  xs="2" class="sublist-mobile-col">
          <v-row>
            <span class="sublist-desktop-col">14045</span>
          </v-row>
          <v-row>
            <span class="sublist-desktop-col">Jalan Tajimin no. 26</span>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="12" md="4" xs="2" class=" nested-label-col-1">
          <span class="label-title">Locker Capacity </span>
        </v-col>
        <v-col md="5" class="nested-label-col-2 sublist-desktop-col" >
          <v-row>
            <v-col class="nested-label-col-2">
              <span style="padding-right: 1em;">Small:</span>
              <span>1</span>
            </v-col>
            <v-col class="nested-label-col-2">
              <span style="padding-right: 1em">Medium:</span>
              <span>2</span>
            </v-col>
            <v-col class="nested-label-col-2">
              <span style="padding-right: 1em">Hard:</span>
              <span>3</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class=" nested-label-col-1">
          <span class="label-title">Integration Id</span>
        </v-col>
        <v-col md="3" class="nested-label-col-2">
          <span class="label nested-label-col-2">T78-xx-xx</span>
        </v-col>
      </v-row>

      <v-row cols="12">
        <v-col cols="12" md="4" xs="1" class=" nested-label-col-1">
          <span class="label-title">Memo</span>
        </v-col>
        <v-col md="4"  class="memo-class memo-mobile-class " >
          <span
            >Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.</span
          >
        </v-col>
      </v-row>

      <div class="fixed-div">
        <v-row cols="12" class="row-btn">
          <v-col cols="4" md="7" xs="1" class="btn-col"> 
            <v-btn class="btn-back-class" rounded color="secondary" dark @click.prevent="goBack">
              Back
            </v-btn>
          </v-col>
          <v-col cols="4" md="2" xs="1" class="btn-col">
            <v-btn class="btn-delete-class" rounded color="red" dark >
              Delete
            </v-btn>
          </v-col>
          <v-col cols="4" md="1" xs="1" class="btn-col">
            <v-btn class="btn-edit-class" rounded color="primary" dark >
              Edit
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "LockerDetail",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 764px) {
  .nested-label-col-1 {
    padding-left: 1em;
    padding-top: 2em;
    padding-bottom: 0;
  }
  .nested-label-col-2 {
    padding-top: 0;
    padding-bottom: 0;
    width: 220vw;
    padding-left: 1em !important;
  }
  .nested-label-col-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sublist-mobile-col {
    padding-top: 0px !important;
    width: 8em !important;
  }
  .row-btn{
    justify-content: space-around !important;
  }
  .btn-col {
    width: fit-content !important;
  }
  .sublist-mobile-title {
    padding-left: 2em !important;
  }
  .memo-mobile-class {
    padding-left: 2em;
    padding-right: 3em;
    width: 20% ;
    height: 110px !important;
    overflow: auto !important;
  }
}
* {
  margin: 0px;
  /* padding: 0px; */
}
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}
.memo-class {
  width: 45% ;
  height: 120px !important;
  overflow: auto !important;
}
.fixed-div {
  margin-top: 12%;
  bottom: 0px;
  right: 50%;
}
.btn-delete-class {
  float: right !important;
}
.sublist-title {
  margin-left: 1em !important;
}
.nested-label-col-1 {
  padding-left: 2em;
}
.sublist-desktop-col {
  padding-left: 0px;
}

</style>
